import { useMutation, useQuery } from '@apollo/client'
import {
  equipment as graphqlEquipment,
  golfClubModel as graphqlGolfClubModel,
  golfClubType as graphqlGolfClubType, SellNow
} from '@graphql'
import { useGraphqlUser, useLocationWithState, useModal, useWindowSize } from '@hooks'
import { CheckoutLayout } from '@layouts'
import {
  AddressCard,
  BuyingOwnGolfClubModalState,
  GolfClub,
  GolfClubModel,
  GolfClubType,
  GolfEquipment,
  GolfEquipmentModel,
  GolfEquipmentType,
  MutationResponse,
  PaymentCard,
  ProductPageItemType,
  PurchaseRequest,
  ResponseType
} from '@types'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import BackArrow from '@images/back-arrow.svg'
import PurseIcon from '@images/purse.svg'
import { useAlert } from 'react-alert'

interface CheckoutSellNow {
  id: string
}
type SellNowMutationResponse = ResponseType<MutationResponse & { result: PurchaseRequest }>

const CheckoutSellNow: React.FC<CheckoutSellNow> = ({ id }) => {
  const alert = useAlert()
  const { fetchCurrentUser } = useGraphqlUser()
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  const { pathname, state } = useLocationWithState<{ type: ProductPageItemType }>()

  const [currentPurchaseRequest, setCurrentPurchaseRequest] = useState<PurchaseRequest | null>(null)

  const currentUser = fetchCurrentUser()
  const itemType = state.type
  const isGolfClubPage = itemType === "GolfClubModel"

  //modals
  const { openModal: openDeleteSimilarGolfClubs } = useModal('DeleteSimilarGolfClubs')
  const { openModal: openBuyingOwnGolfClubModal } = useModal<BuyingOwnGolfClubModalState>('BuyingOwnGolfClubModal')
  const { openModal: openSellerPaymentMethodWarningModal } = useModal('SellerPaymentMethodWarning')
  //

  const [sellNow, { data: sellNowData, loading: sellNowLoading }] = useMutation<
    SellNowMutationResponse,
    { purchaseRequestId: string }
  >(SellNow)


  /**
    * * isGolfClubPage === true - fetch golfClub Types
    * * isGolfClubPage === false - fetch golfEquipment Types
  */
  const golfItemTypeQuery = isGolfClubPage ? graphqlGolfClubType.FetchGolfClubType : graphqlEquipment.FetchGolfEquipmentType
  const { data: golfTypeData, loading: golfTypeLoading } = useQuery<ResponseType<GolfClubType | GolfEquipmentType>>(
    golfItemTypeQuery,
    { variables: { id }, fetchPolicy: 'no-cache' }
  )

  const pathnameIndex = pathname.indexOf('sell-now')
  const checkoutSuccessValue = pathname.substr(pathnameIndex)
  const golfType = golfTypeData?.res

  let golfItems: (GolfEquipment | GolfClub)[] = []
  let golfModel: GolfEquipmentModel | GolfClubModel

  if (golfType && 'golfClubs' in golfType) {
    golfItems = golfType.golfClubs
    golfModel = golfType.golfClubModel
  } else if (golfType && 'golfEquipments' in golfType) {
    golfItems = golfType.golfEquipments
    golfModel = golfType.golfEquipmentModel
  }

  const usersSimilarGolfClubs = (golfItems || []).filter((golfItem: GolfClub | GolfEquipment) => {
    const isCurrentUsersGolfClub = currentUser?.id === golfItem?.owner?.id
    return golfItem.status === 'ON_SALE' && isCurrentUsersGolfClub
  })

  useEffect(() => {
    const isUserStripeAccountNotSubmitted =
      currentUser && currentUser.sellerStatus === 'SELLER' && !currentUser.stripeAccountSubmitted
    if (isUserStripeAccountNotSubmitted) {
      openSellerPaymentMethodWarningModal()
    }
  }, [currentUser])

  useEffect(() => {
    if (!currentPurchaseRequest && golfType?.openPurchaseRequests) {
      setCurrentPurchaseRequest(golfType.openPurchaseRequests[0] || null)
    }
  }, [golfTypeData])

  useEffect(() => {
    if (sellNowData?.res?.successful === true) {
      navigate('/checkout-success', { state: { isSell: true, checkoutSuccessValue: checkoutSuccessValue, type: itemType } })
    }
  }, [sellNowData])

  const handleConfirm = async (
    addressCard?: AddressCard,
    paymentCard?: PaymentCard,
    hasBeenDeleteSimilarGolfClubsModalOpened?: boolean
  ) => {
    const paymentCardId = paymentCard?.id
    const addressCardId = addressCard?.id

    if (!hasBeenDeleteSimilarGolfClubsModalOpened && usersSimilarGolfClubs.length) {
      const usersSimilarGolfClubIds = usersSimilarGolfClubs.map(golfClub => golfClub.id)
      openDeleteSimilarGolfClubs({ ids: usersSimilarGolfClubIds, callback: () => handleConfirm(addressCard, paymentCard, true) })
      return
    }

    if (currentPurchaseRequest?.user?.id === currentUser?.id) {
      openBuyingOwnGolfClubModal({ slug: golfModel.slug, isSell: true })
      return
    }

    if (currentPurchaseRequest) {
      if (paymentCardId || addressCardId) {
        const payload = {
          purchaseRequestId: currentPurchaseRequest.id,
          paymentCardId,
          addressCardId
        }

        await sellNow({
          variables: payload,
          refetchQueries: [{ query: graphqlGolfClubModel.FetchGolfClubModel, variables: { id: golfModel.id || '' } }]
        }).then(response => {
          const golfModelSlug = golfModel.slug
          const status = response.data?.res.successful

          if (status === false) {
            const errorDetail = response.data?.res?.messages[0].message
            alert.show(`Error! ${errorDetail}`, {
              type: 'error'
            })

            navigate(`/product/${golfModelSlug}/${itemType}`)
          }
        })
      }
    }
  }

  return (
    <CheckoutLayout
      golfTypeLoading={golfTypeLoading}
      golfTypeData={golfTypeData}
      handleConfirm={handleConfirm}
      price={currentPurchaseRequest?.sellerProfit || 0}
      title={
        <div>
          <strong className="checkout-payment-info-title subtitle1">
            {isMobileRender && <BackArrow onClick={() => navigate(-1)} />}
            <PurseIcon />
            Sell Now
          </strong>
        </div>
      }
      mutationLoading={sellNowLoading}
    >
      <></>
    </CheckoutLayout>
  )
}

export default CheckoutSellNow
